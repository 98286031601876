import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';

const SubscriptionBadge = ({ status }) => {
    const isPremium = status === 'Premium User';
    const controls = useAnimation();
    const [isHovered, setIsHovered] = useState(false);

    const badgeStyle = isPremium
        ? 'bg-gradient-to-r from-yellow-400 to-yellow-600 text-black'
        : 'bg-gradient-to-r from-blue-500 to-purple-600 text-white';

    const badgeText = isPremium ? 'Premium' : isHovered ? 'Upgrade Now' : 'Free Tier';

    useEffect(() => {
        controls.start({ opacity: 1, transition: { duration: 0.5 } });
    }, [controls]);

    return (
        <Link to="/settings/billing" className="no-underline">
            <motion.div
                initial={{ opacity: 0 }}
                animate={controls}
                whileHover={{ scale: 1.05 }}
                onHoverStart={() => setIsHovered(true)}
                onHoverEnd={() => setIsHovered(false)}
                className={`flex justify-center items-center px-3 py-1 text-sm font-semibold rounded-full shadow-lg transition-all duration-300 cursor-pointer ${badgeStyle}`}
            >
                <span className="mr-1 text-center">{badgeText}</span>
                {isPremium ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path
                            fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                            clipRule="evenodd"
                        />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path
                            fillRule="evenodd"
                            d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                            clipRule="evenodd"
                        />
                    </svg>
                )}
            </motion.div>
        </Link>
    );
};

export default SubscriptionBadge;
