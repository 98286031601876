import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import {
    CalendarMonth as CalendarMonthIcon,
    Home as HomeIcon,
    Settings as SettingsIcon,
    Task as TaskIcon,
    Event as EventIcon, // Import the icon for Upcoming Events
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { AddTaskButton } from './components/AddTaskButton';
import { useTaskModal } from './contexts/TaskModalContext';

const BottomNavBar = ({ navigateValue }) => {
    const isMobile = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
    const { openTaskModal } = useTaskModal();

    if (!isMobile) {
        return <div></div>;
    }
    if (!isMobile) {
        return (
            <div className="flex fixed right-0 bottom-0 left-0 z-50 justify-center items-center p-4 bg-transparent">
                <AddTaskButton
                    className="p-4 transition-transform duration-200 transform hover:scale-110 bottom-add-task-button"
                    iconProps={{
                        className: 'text-5xl bg-white rounded-full text-green-500',
                    }}
                    openTaskModal={openTaskModal}
                />
            </div>
        );
    } else {
        return (
            <Paper
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent', zIndex: 10 }}
                elevation={3}
            >
                <BottomNavigation
                    value={navigateValue}
                    sx={{
                        '& .MuiBottomNavigationAction-root': {
                            color: '#0C4064',
                            '&:hover': {
                                backgroundColor: 'rgba(12, 64, 100, 0.1)',
                            },
                        },
                    }}
                >
                    <BottomNavigationAction
                        label="Home"
                        component={Link}
                        to="/home"
                        value="/home"
                        icon={<HomeIcon />}
                    />
                    <BottomNavigationAction
                        label="Upcoming Events"
                        component={Link}
                        to="/upcoming-events"
                        value="/upcoming-events"
                        icon={<EventIcon />}
                    />
                    <BottomNavigationAction
                        label="Add Task"
                        icon={
                            <AddTaskButton
                                className="z-50 p-0 bottom-add-task-button"
                                iconProps={{ sx: { fontSize: 24 } }}
                                openTaskModal={openTaskModal}
                            />
                        }
                    />
                    <BottomNavigationAction
                        to="/calendar"
                        component={Link}
                        label="Calendar"
                        value="/calendar"
                        icon={<CalendarMonthIcon />}
                    />
                    <BottomNavigationAction
                        component={Link}
                        to="/settings"
                        value="/settings"
                        label="Settings"
                        icon={<SettingsIcon />}
                    />
                </BottomNavigation>
            </Paper>
        );
    }
};

export default BottomNavBar;
