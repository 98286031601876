import React, { lazy, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import motion from framer-motion
import {
    CalendarMonth as CalendarMonthIcon,
    Home as HomeIcon,
    Settings as SettingsIcon,
    Task as TaskIcon,
    EventNote as PlannerIcon,
    Repeat as RepeatIcon,
    PriorityHigh as PriorityIcon,
} from '@mui/icons-material';
import logo_no_text from '../public/images/logo-no-text.png';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useAtom } from 'jotai';
import { isReschedulingAtom } from './atoms/isReschedulingAtom';
import { useCustomer } from './providers/CustomerProvider';
import { useTasks } from './providers/TasksProvider';
import { ActionBar } from './ActionBar';
import { useTaskModal } from './contexts/TaskModalContext';
import { isAuthenticatedAtom } from './atoms/authAtoms';
import { AddTaskButton } from './components/AddTaskButton';
import Tooltip from '@mui/material/Tooltip';
import SubscriptionBadge from './SubscriptionBadge';
import { Modal, Button } from 'antd'; // Import Modal and Button from antd

const TaskModal = lazy(() => import('./components/tasks/TaskModal'));

const itemVariants = {
    hover: { scale: 1.05, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' },
    tap: { scale: 0.95 },
};

function TopNavBar() {
    const [isRescheduling] = useAtom(isReschedulingAtom);
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const { tasks, isLoading: isLoadingTasks, error } = useTasks();
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const { openTaskModal } = useTaskModal();
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Function to safely count tasks
    const countTasks = (taskList) => {
        if (!Array.isArray(taskList)) return 0;
        return taskList.filter((task) => task && typeof task === 'object').length;
    };

    // Re-render when customer changes
    useEffect(() => {
        // This effect will run whenever the customer changes
    }, [customer]);

    // Render a minimal placeholder when loading
    if (isLoadingCustomer || isLoadingTasks) {
        return (
            <div className="h-16 bg-white shadow-sm">
                {/* Minimal content to maintain layout */}
                <div className="flex items-center px-4 h-full">
                    <img src={logo_no_text} alt="Logo" className="h-10" />
                </div>
            </div>
        );
    }

    // If there is no customer, return empty div
    if (!customer) {
        return <div></div>;
    }

    const subscriptionStatus = customer.stripeStatus === 'active' ? 'Premium User' : 'Free Tier';

    return (
        <div
            className="sticky top-0 z-50 justify-between items-center navbar"
            role="navigation"
            aria-label="Main Navigation"
        >
            <div className="flex items-center navbar-start">
                <img src={logo_no_text} alt="Logo" className="hidden mr-4 h-14 lg:block mt-[-7px]" />
                <div className="dropdown lg:hidden">
                    <label tabIndex={0} className="btn btn-ghost" aria-label="Menu">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16"
                            />
                        </svg>
                    </label>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-52"
                        role="menu"
                    >
                        <li className="mb-2 bg-white card bordered">
                            <Tooltip title="Home" arrow>
                                <Link to="/home" className="block card-body" role="menuitem">
                                    <HomeIcon className="text-[#00AEEF]" /> Home
                                </Link>
                            </Tooltip>
                        </li>
                        <li className="mb-2 bg-white card bordered">
                            <Tooltip title="Stats" arrow>
                                <Link to="/stats" className="block card-body" role="menuitem">
                                    <TaskIcon className="text-[#80D7F7]" /> Stats
                                </Link>
                            </Tooltip>
                        </li>
                        <li className="mb-2 bg-white card bordered">
                            <Tooltip title="Planner" arrow>
                                <Link to="/planner" className="block card-body plannerclass" role="menuitem">
                                    <PlannerIcon className="text-[#FFC60B]" /> Planner
                                </Link>
                            </Tooltip>
                        </li>
                        <li className="mb-2 bg-white calendarclass card bordered">
                            <Tooltip title="Calendar" arrow>
                                <Link to="/calendar" className="block card-body" role="menuitem">
                                    <CalendarMonthIcon className="text-[#00AEEF]" /> Calendar
                                </Link>
                            </Tooltip>
                        </li>
                        <li className="mb-2 bg-white card bordered">
                            <Tooltip title="Habits" arrow>
                                <Link to="/habits" className="block card-body habitsclass" role="menuitem">
                                    <RepeatIcon className="text-[#FFC60B]" /> Habits
                                </Link>
                            </Tooltip>
                        </li>
                        <li className="bg-white card bordered">
                            <Tooltip title="Settings" arrow>
                                <Link to="/settings" className="block card-body" role="menuitem">
                                    <SettingsIcon className="text-[#80D7F7]" /> Settings
                                </Link>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
                <div className="hidden ml-0 text-3xl mt font-GilroyExtraBold lg:block">
                    <Link to="/home" aria-label="Mind Your Now Home">
                        <span className="text-[#00AEEF]">mind</span>
                        <span className="font-normal text-[#80D7F7]">your</span>
                        <span className="text-[#FFC60B]">now</span>
                    </Link>
                </div>

                {/* Add Help / Feedback link */}
                <Modal
                    title="Mind Your Now - Early Access"
                    open={isModalOpen}
                    onOk={handleCloseModal}
                    onCancel={handleCloseModal}
                >
                    <p className="text-lg">
                        Mind Your Now is still in early access. For any issues or feedback, please email us at{' '}
                        <a href="mailto:support@mindyournow.com" className="text-blue-500 hover:underline">
                            support@mindyournow.com
                        </a>
                    </p>
                </Modal>
            </div>
            {isRescheduling && (
                <div className="hidden items-center mx-20 lg:flex" aria-live="polite">
                    <Paper
                        elevation={3}
                        className="flex items-center px-3 py-1 rounded-full"
                        style={{ backgroundColor: '#0C4064', color: '#FFC60B' }}
                    >
                        <CircularProgress size={34} style={{ color: '#FFC60B' }} className="mr-2" />
                        <span>Rescheduling</span>
                    </Paper>
                </div>
            )}
            <div className="hidden navbar-center lg:block">
                <ul className="flex items-center" role="menubar">
                    <NavItem to="/home" icon={HomeIcon} text="Home" color="text-myncritical-400" />
                    <NavItem to="/planner" icon={PlannerIcon} text="Planner" color="text-mynoverthehorizon-400" />
                    <NavItem to="/priorities" icon={PriorityIcon} text="Priorities" color="text-purple-500" />
                    <NavItem to="/stats" icon={TaskIcon} text="Stats" color="text-mynopportunitynow-400" />
                    <NavItem to="/calendar" icon={CalendarMonthIcon} text="Calendar" color="text-mynparkinglot-400" />
                    <NavItem to="/habits" icon={RepeatIcon} text="Habits" color="text-[#FF6B6B]" />{' '}
                    {/* Changed color */}
                    <AddTaskButton className="ml-2" openTaskModal={openTaskModal} aria-label="Add Task" />
                </ul>
            </div>

            <div className="items-center ml-auto navbar-end lg:flex">
                <Button
                    onClick={handleOpenModal}
                    className="px-4 py-2 mr-4 text-white bg-blue-500 rounded-full shadow-md transition-colors duration-300 hover:bg-blue-600"
                >
                    Help / Feedback
                </Button>
                <div className="hidden mr-4 w-36 lg:block">
                    {' '}
                    {/* Reduced width to w-36 (9rem) */}
                    <SubscriptionBadge status={subscriptionStatus} />
                </div>
                <div className="flex items-center p-2 bg-white rounded-lg actionbarwrapper">
                    <ActionBar />
                </div>
            </div>
        </div>
    );
}

// New NavItem component for better accessibility and hover effects
const NavItem = ({ to, icon: Icon, text, color }) => (
    <motion.li role="none" whileHover="hover" whileTap="tap" variants={itemVariants}>
        <Paper elevation={3} className="p-2 mx-4 rounded-lg">
            <Tooltip title={text} arrow>
                <Link
                    to={to}
                    className={`flex items-center text-xl font-semibold transition-colors duration-300 ${color} hover:opacity-80 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none`}
                    role="menuitem"
                >
                    <Icon className={`mr-2 ${color}`} />
                    {text}
                </Link>
            </Tooltip>
        </Paper>
    </motion.li>
);

export default TopNavBar;
